
.header.about {
    position: relative;
    overflow: hidden;
    height: 100%;
}

.about-head-img-container {
    width: 100vw;
    position: absolute;
    top: 0px;
}


.about-head-img-content img{
    width: 100%;
}

.about-head-content {
    position: relative;
}

.counter-container {
    padding: 30px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: var(--dark-green);
    -webkit-box-shadow: 0px 20px 20px -9px rgba(0, 0, 0, 0.148);
    -moz-box-shadow: 0px 20px 20px -9px rgba(0, 0, 0, 0.148);
    box-shadow: 0px 20px 20px -9px rgba(0, 0, 0, 0.148);
}

.counter-title h2, .counter-subtitle h2{
    font-weight: 400;
}

.counter-numbers h1 {
    color: #fff;
    font-size: 4em;
    font-weight: 700;
    margin: 10px 0px;
}

.history-description b {
    color: var(--main-green)
}

.collage-container {
    position: relative;
    height: 100vh;
    width: 100%;
}

.collage-img {
    position: absolute;
    cursor: pointer;
    border-radius: 10px;
    -webkit-box-shadow: 0 0 17px 2px rgba(139, 139, 139, 0.75);
    -moz-box-shadow: 0 0 17px 2px rgba(139, 139, 139, 0.75);
    border: 3px solid #fff;
    background-size: cover;
    background-position: center;
}
.c0:hover {
    animation: hovered1 1.5s;
}
.c1:hover, 
.c2:hover {
    animation: hovered1 1.5s;
    z-index: 251;
}
.c3:hover, .c4:hover, .c5:hover, .c6:hover, .c7:hover, .c8:hover, .c9:hover {
    animation: hovered2 1.5s;
    z-index: 251;
}
.history-container {
    position: relative;
}
.history-info {
    z-index: 9;
}
.history-container::after {
    top: -50%;
    height: 200%;
    width: 100%;
    position: absolute;
    content: "";
    overflow: visible;
    background-image: url("./../../assets/img/imaging/BodyBack.svg");
    background-repeat: no-repeat;
    background-size: auto;
    background-position: right;
}
@keyframes collageAnimation1 {
    0% {transform: scale(1);}
    50% {transform: scale(1.03);}
    100% {transform: scale(1);}
}
@keyframes collageAnimation2 {
    0% {transform: scale(1);}
    50% {transform: scale(1.02);}
    100% {transform: scale(1);}
}
@keyframes collageAnimation3 {
    0% {transform: scale(1);}
    50% {transform: scale(1.01);}
    100% {transform: scale(1);}
}
@keyframes hovered1 {
    0% {transform: scale(1); }
    50% {transform: scale(1.05); border: 3px solid var(--main-green);}
    100% {transform: scale(1);} 
}
@keyframes hovered2 {
    0% {transform: scale(1);}
    50% {transform: scale(1.03); border: 3px solid var(--main-green);}
    100% {transform: scale(1);} 
}
@media only screen and (max-width: 768px) { 

        
    
/* C0 */
.c0 {
    top: 27.5%;
    left: 0%;
    height: 45%;
    width: 100%;
    animation: collageAnimation1 5s ;
    animation-iteration-count: infinite;
    z-index: 250;
}
/* C1 */
.c1 {
    top: 5%;
    left: 0%;
    height: 21%;
    width: 40%;
    animation: collageAnimation2 3s ;
    animation-iteration-count: infinite;
    z-index: 240;
}
/* C2 */
.c2 {
    bottom: calc(0% + 15px);
    left: 0%;
    height: 23%;
    width: 65%;
    animation: collageAnimation2 4s ;
    animation-iteration-count: infinite;
    z-index: 240;
}
/* C3 */
.c3 {
    bottom: calc(0% + 15px);
    right: 1.5%;
    height: 22%;
    width: 30%;
    animation: collageAnimation2 4s ;
    animation-iteration-count: infinite;
    z-index: 240;
}
/* C4 */
.c4, .c5, .c6, .c7 {
    display: none;
}

/* C8 */
.c8 {
    top: 5%;
    right: -1.5%;
    height: 21%;
    width: 20%;
    animation: collageAnimation2 3s ;
    animation-iteration-count: infinite;
    z-index: 240;
}

/* C8 */
.c9 {
    top: 4%;
    right: 21.5%;
    height: 20%;
    width: 35%;
    animation: collageAnimation2 3s ;
    animation-iteration-count: infinite;
    z-index: 240;
}

    .history-container::after {
        top: -50%;
        height: 100%;
        width: 100%;
    }
    .about-logo-container {
        position: relative;
        z-index: 99;
    }
    .about-head-img-container {
        height: 100%;
        width: 100vw;
    }

    .about-head-img-content {
        background-image: url('./../../assets/img/nosotrosBackground.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position-x: right;
        position: relative;
        height: 100%;
        width: 100vw;
        overflow: hidden;
    }
    .about-head-content {
        margin-top: 70vh;
    }
    .about-head-img-content img{
        display: none;
    }
    .about-head-img-content::after {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        right: -20px;
        top: 0;
        background: linear-gradient(0deg, #fff 20%, transparent 40%) ;
    }

    /* Values */
    .values-container {
        display: flex;
        flex-direction: column;
        transition: all 300ms ease-in-out;
        -webkit-box-shadow: 0px 20px 20px -9px rgba(0, 0, 0, 0.148);
        -moz-box-shadow: 0px 20px 20px -9px rgba(0, 0, 0, 0.148);
        box-shadow: 0px 20px 20px -9px rgba(0, 0, 0, 0.148);
    }
    
    .value-image {
        overflow: hidden;
        position: relative;
        background-image: url('./../../assets/img/valuesImage.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: right;
        min-height: 50vh;
        width: 100%;
    }

    .value-image::after {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        right: 0%;
        bottom: -13%;
        background: linear-gradient(0deg, #fff 10%, transparent 17%) ;
    }
    
    .value-info {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 50px 15vw;
        width: 100%;
    }

    .value-title h1 {
        font-size: 40px;
    }

    .value-subtitle {
        margin: 0.5em 0;
    }
    
    .value-description { 
        margin-top: 1em;
        color: var(--dark-grey);
    }

    .history-description { 
        margin-top: 1em;
        color: var(--dark-grey);
    }

    .history-description p{ 
        text-align: justify;
    }

    .value-description ul {
        margin-top: 1em;
        padding: 0;
    }
    
    .value-description ul li::marker{
        color: var(--main-green);
    }

    /* Mision Vision */

    .mv-container {
        display: flex;
        flex-wrap: wrap;
        /* background: linear-gradient(90deg, var(--main-green) 50%, var(--dark-green) 50%) ; */
    }

    .mv-info {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 50px 15vw;
        width: 100%;
        z-index: 9;
    }

    .history-container {
        padding: 0 10%;
    }

    .history-title {
        text-align: center;
    }
}

@media only screen and (min-width: 768px) { 
    
    
/* C0 */
.c0 {
    top: 27.5%;
    left: 27.5%;
    height: 45%;
    width: 45%;
    animation: collageAnimation1 5s ;
    animation-iteration-count: infinite;
    z-index: 250;
}
/* C1 */
.c1 {
    top: calc(0% + 60px);
    left: 5%;
    height: 30%;
    width: 30%;
    animation: collageAnimation2 3s ;
    animation-iteration-count: infinite;
    z-index: 240;
}
/* C2 */
.c2 {
    bottom: calc(0% + 15px);
    left: 5%;
    height: 30%;
    width: 35%;
    animation: collageAnimation2 4s ;
    animation-iteration-count: infinite;
    z-index: 240;
}
/* C3 */
.c3 {
    bottom: calc(0% + 15px);
    right: 27.5%;
    height: 22%;
    width: 30%;
    animation: collageAnimation2 4s ;
    animation-iteration-count: infinite;
    z-index: 240;
}
/* C4 */
.c4 {
    bottom: calc(0% + 30px);
    right: 0%;
    height: 25%;
    width: 25%;
    animation: collageAnimation2 3s ;
    animation-iteration-count: infinite;
    z-index: 240;
}
/* C5 */
.c5 {
    bottom: 36%;
    left: 6%;
    height: 20%;
    width: 20%;
    animation: collageAnimation2 3s ;
    animation-iteration-count: infinite;
    z-index: 240;
}
/* C6 */
.c6 {
    top: 44%;
    right: 2.5%;
    height: 23%;
    width: 23%;
    animation: collageAnimation2 3s ;
    animation-iteration-count: infinite;
    z-index: 240;
}

/* C7 */
.c7 {
    top: 10%;
    left: 37%;
    height: 15%;
    width: 15%;
    animation: collageAnimation2 3s ;
    animation-iteration-count: infinite;
    z-index: 240;
}

/* C8 */
.c8 {
    top: 10%;
    right: 9.5%;
    height: 32%;
    width: 15%;
    animation: collageAnimation2 3s ;
    animation-iteration-count: infinite;
    z-index: 240;
}

/* C8 */
.c9 {
    top: 10%;
    right: 26.5%;
    height: 16%;
    width: 20%;
    animation: collageAnimation2 3s ;
    animation-iteration-count: infinite;
    z-index: 240;
}

    .about-head-img-content {
        position: relative;
        height: 100%;
        width: 100vw;
    }
    .about-head-content {
        margin-top: 10vh;
    }
    .about-head-img-content::after {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        background: linear-gradient(0deg, #fff 40%, transparent 80%) ;
    }

    /* Values */
    .values-container {
        min-height: 60vh;
        display: flex;
        transition: all 300ms ease-in-out;
        -webkit-box-shadow: 0px 20px 20px -9px rgba(0, 0, 0, 0.048);
        -moz-box-shadow: 0px 20px 20px -9px rgba(0, 0, 0, 0.048);
        box-shadow: 0px 20px 20px -9px rgba(0, 0, 0, 0.048);
    }
    
    .value-image {
        overflow: hidden;
        position: relative;
        background-image: url('./../../assets/img/valuesImage.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: right;
        min-height: 60vh;
        width: 40%;
    }

    .value-image::after {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        right: -13%;
        top: 0;
        background: linear-gradient(270deg, #fff 10%, transparent 17%) ;
    }

    .value-subtitle {
        margin: 0.5em 0;
    }
    
    .value-info {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 30px 70px;
        width: 60%;
    }

    .value-description { 
        margin-top: 1em;
        color: var(--dark-grey);
    }

    .history-description { 
        margin-top: 1em;
        color: var(--dark-grey);
    }

    .history-description p{ 
        text-align: justify;
    }

    .value-description ul {
        margin-top: 1em;
        padding: 0;
    }

    .value-description ul li::marker{
        color: var(--main-green)
    }

    /* Mision Vision */

    .mv-container {
        min-height: 50vh;
        display: flex;
        flex-wrap: wrap;
        /* background: linear-gradient(90deg, var(--main-green) 50%, var(--dark-green) 50%) ; */
    }

    .mv-info {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 50px 70px;
        width: 50%;
        z-index: 9;
    }

    .history-info {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 50px 70px;
        width: 100%;
    }
}

@media only screen and (min-width: 1080px) { 
    
    .about-head-img-content {
        position: relative;
        height: 100%;
        width: 100vw;
    }

    .about-head-content {
        margin-top: 0vh;
    }
    
    .about-head-img-content::after {
        position: absolute;
        content: "";
        height: 100%;
        width: 40%;
        left: 0;
        top: 0;
        background-color: #fff;
    }

    .about-head-img-content::before {
        position: absolute;
        content: "";
        height: 100%;
        width: 70%;
        left: 30%;
        top: 0;
        background: linear-gradient(90deg, #fff 20%, transparent 40%) ;
    }

}