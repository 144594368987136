
.page-not-found-container {
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.not-found-subtitle {
    text-align: center;
    margin-top: 1em;
    margin-bottom: 1em;
    padding: 0 20vw;
}
.not-found-btn {
    font-weight: 700;
    transition: all 200ms ease-in-out;
    cursor: pointer;
}
.not-found-btn:hover {
    color: var(--dark-green);
    background-color: var(--main-green);
}

@media only screen and (max-width: 768px) {
    .not-found-title {
        font-size: 3em;
        font-weight: 700;
    }
    .not-found-btn {
        margin-top: 1em;
        padding: 0.7em 1.4em;
        background: var(--dark-green);
        border-radius: 50px;
    }
}

@media only screen and (min-width: 768px) {
    .not-found-title {
        font-size: 5em;
        font-weight: 700;
    }
    .not-found-btn {
        margin-top: 1em;
        padding: 1em 2em;
        background: var(--dark-green);
        border-radius: 50px;
    }
}