
.wordCarousel-container {
    display: flex;
    /* align-items: flex-start; */
    height: 45px;
}


.wordCarousel {
    /* margin-left: 15px; */
    display: inline-block
}
.wordCarousel{
    display: inline-block;
    overflow: hidden;
    height: 65px;
    width: 100%;
    /* padding-top: 10px; */
    /* margin-top: -10px; */
}
.wordCarousel-comp {
    display: inline-block;
    height: 65px;
    padding-top: 10px;
    margin-top: -10px;
}

.wordCarousel div{
    text-align: left;
    font-weight: 700;
    height: 45px;
    margin-bottom: 45px;
}

@media only screen and (max-width: 768px) { 
    
    .wordCarousel-container {
        align-items: center;
    }
    .wordCarousel{
        height: 45px;
    }
    .wordCarousel-comp {
        height: 45px;
    }
    .wordCarousel div {
        font-size: 35px;
        text-align: center;
    }
    .wordCarousel-container h1{
        font-size: 35px;
    } 
}

@media only screen and (min-width: 768px) { 
    
    .wordCarousel-container {
        align-items: center;
    }

    .flip4 div{
        text-align: center;
    }
}


@media only screen and (min-width: 1080px) { 
    
    .wordCarousel-container {
        align-items: center;
    }

    .flip4 div{
        text-align: left;
    }
}

.flip2 { animation: flip2 6s cubic-bezier(0.23, 1, 0.32, 1.2) infinite; }
.flip3 { animation: flip3 8s cubic-bezier(0.23, 1, 0.32, 1.2) infinite; }
.flip4 { animation: flip4 10s cubic-bezier(0.23, 1, 0.32, 1.2) infinite; }

@keyframes flip2 {
    0% { margin-top: -180px; }
    5% { margin-top: -90px;  }
    50% { margin-top: -90px; }
    55% { margin-top: 0px; }
    99.99% { margin-top: 0px; }
    100% { margin-top: -270px; }
}

@keyframes flip3 {
    0% { margin-top: -270px; }
    5% { margin-top: -180px; }
    33% { margin-top: -180px; }
    38% { margin-top: -90px; }
    66% { margin-top: -90px; }
    71% { margin-top: 0px; }
    99.99% { margin-top: 0px; }
    100% { margin-top: -270px; }
}

@keyframes flip4 {
    0% { margin-top: -360px; }
    5% { margin-top: -270px; }
    25% { margin-top: -270px; }
    30% { margin-top: -180px; }
    50% { margin-top: -180px; }
    55% { margin-top: -90px; }
    75% { margin-top: -90px; }
    80% { margin-top: 0px; }
    99.99% { margin-top: 0px; }
    100% { margin-top: -270px; }
}
