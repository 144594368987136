* {
    box-sizing: border-box;
    margin: 0;
}
:root {
    --main-green: #99cc33;
    --light-green: #d7e9b641;
    --secondary-green: #339933;
    --dark-green: #355e3b;
    --light-grey: #f4f4f4;
    --dark-grey: #979797;
}
body{
    overflow-x: hidden;
    /* background-color: var(--light-grey); */
}
html {
    scroll-behavior: smooth;
}
/* Navigation bar */
.nav-bar {
    background-color: #fff;
    transition: all 200ms ease-in-out;
    z-index: 210;
    height: 60px;
    justify-content: space-between;
}

.nav-bar.drop-shadow {
    -webkit-box-shadow: 0px 15px 26px -5px rgba(0,0,0,0.03);
    -moz-box-shadow: 0px 15px 26px -5px rgba(0,0,0,0.03);
    box-shadow: 0px 15px 26px -5px rgba(0,0,0,0.03);
    /* height: 65px; */
}

.nav-bar ul li {
    display: inline-block;
    margin: 20px 15px;
    position: relative;
}
.nav-bar ul li span {
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    transition: color 200ms ease-in-out;
    text-decoration: none;
}
.nav-bar ul li a {
    text-decoration: none;
}
.nav-bar ul li span:hover {
    color: var(--main-green);
}
.nav-bar ul li::after {
    opacity: 0;
}
.nav-bar ul li:hover::after {
    opacity: 1;
}

.navbar-menu {
    display: flex;
    justify-content: flex-end;
    padding-right: 5vw;
}
.active:hover::before {
    opacity: 1;
}
.cert-btn {
    background-color: var(--main-green);
    color: white;
    font-weight: bold;
    padding: 10px 15px;
    border-radius: 30px;
    cursor: pointer;
    transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
    margin-left: 15px;
}

.inter-cert-btn {
    width: 100%;
    display: flex;
    justify-content: center;
}

.inter-cert-btn a{
    text-decoration: none;
}
.cert-btn:hover {
    background-color: var(--dark-green);
    color: var(--main-green);
}
/* Header */
.header-content p {
    margin-top: 1em;
}
.header-bg {
    height: 100vh;
    background-image: url("../assets/img/headerbg.png");
    background-size: cover;
    -webkit-transform: scaleX(-1.5) scaleY(1.5);
    transform: scaleX(-1.5) scaleY(1.5);
    z-index: 11;
}
.header-filter {
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}
/* Services */
.services-bg {
    top: -45vh;
    background-image: url("../assets/img/ServBackground.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    z-index: 94;
}
.services-container {
    padding-left: 40px;
    padding-right: 40px;
}
.services-contain {
    /* margin-top: 50px; */
    flex-wrap: wrap;
    justify-content: center;
}
.service-box {
    height: 135px;
    border-radius: 15px;
    position: relative;
    transition: all 300ms ease-in-out;
    -webkit-box-shadow: 0 0 25px 2px rgba(0, 0, 0,060.1);
    -moz-box-shadow: 0 0 25px 2px rgba(0,0,0,0.06);
    box-shadow: 0 0 25px 2px rgba(0,0,0,0.06);
}

.service-box h4{ 
    width: 90%;
    align-self: center;
}

.service-box .service-icon {
    align-self: center;
}
.service-name {
    transition: color 200ms ease-in-out;
    line-height: 100%;
}
#service-icon {
    align-self: center;
    justify-self: center;
}
.service-icon {
    opacity: 1;
    width: 40%;
    margin: auto;
    position: relative;
    cursor: pointer;
    transition: opacity 1s ease-in-out;
}
.service-btn {
    display: none;
    opacity: 0;
    font-size: 18px;
    font-weight: bold;
    padding: 7px 15px;
    background-color: var(--main-green);
    color: var(--light-grey);
    transition: opacity 1s ease-in-out, padding 200ms ease-in-out, color 200ms ease-in-out, background-color 200ms ease-in-out;
}
.service-btn:active,
.service-btn:hover {
    padding: 9px 18px;
    background-color: var(--dark-green) !important;
    color: var(--main-green);
}

.service-box:active .service-name,
.service-box:hover .service-name {
    color: var(--main-green);
}
/* Service Navigation */
.serv-nav {
    opacity: 1;
    top: 80px;
    visibility: visible;
    padding: 20px;
    height: auto;
    width: 35%;
    border-radius: 15px;
    background-color: var(--light-grey);
    -webkit-box-shadow: 0 0 17px 2px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 0 17px 2px rgba(0,0,0,0.05);
    box-shadow: 0 0 17px 2px rgba(0,0,0,0.05);
    transition: visibility 400ms ease-in-out;
}
.serv-nav::after {
    top: -10px;
    left: 20px;
    height: 10px;
    width: 20px;
    position: absolute;
    content: "";
    clip-path: polygon(50% 21%, 0% 100%, 100% 100%);
    background-color: var(--light-grey);
}
.serv-nav a {
    cursor: pointer;
    color: var(--dark-grey);
}
.serv-nav a:hover {
    color: var(--main-green);
}
.serv-nav-hidden {
    top: 80px;
    opacity: 0;
    visibility: hidden;
}
.serv-nav-content {
    justify-content: center;
    flex-wrap: wrap;
}
.serv-nav-item {
    align-self: center;
    cursor: pointer;
    border-radius: 5px;
    padding: 10px;
    width: 45%;
}
.serv-nav-item:hover a {
    color: var(--main-green);
}
.serv-nav-icon {
    width: 40px;
}
.grid-serv-nav-item {
    grid-template-columns: 30% 70%;
}

.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip:hover .tooltiptext {
    opacity: 1;
}

.tooltip a{
    text-decoration: none;
}

.tooltip .tooltiptext {
    width: 120px;
    background-color: var(--secondary-green);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 5px;
    position: absolute;
    z-index: 1;
    top: 200%;
    left: 56%;
    margin-left: -60px;
    transition: opacity 400ms ease-in-out;
    opacity: 0;
    }

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 100%;
    right: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #339933 transparent;
}
/* Service Window */
.fa-times-circle {
    top: 0;
    right: 0;
    padding: 30px;
    cursor: pointer;
    color: var(--dark-grey);
    font-size: 25px;
    transition: color 200ms ease-in-out;
    z-index: 9999999;
}
.fa-times-circle:active,
.fa-times-circle:hover {
    color: var(--main-green);
}
.click-to-show {
    top: 70px;
    left: 0;
    padding: 20px;
    cursor: pointer;
}
/* Sticky Cart */
.sticky-cart {
    display: inline-block;
    height: 50px;
    width: 100px;
    bottom: 0;
    right: 0;
    border-radius: 30px;
    margin: 30px;
    z-index: 995;
    cursor: pointer;
}
.sticky-title {
    padding-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
    color: #fff;
    display: inline-block;
}
.sticky-circle {
    display: inline-block;
    top: 5px;
    right: 5px;
    height: 40px;
    width: 40px;
    padding: 7px;
    border-radius: 50%;
    background-color: #fff;
}
.sticky-services {
    background-color: #fff;
    overflow: auto;
    width: 260px;
    bottom: 90px;
    right: 30px;
    transition: height 400ms ease-in-out, border-radius 500ms ease-in-out;
}

.sticky-services-content-container {
    position: relative;
    height: 100%;
    width: 100%;
}

.service-window-content span {
    top: 20px;
    right: 20px;
    display: flex;
    padding-bottom: 1px;
    padding-right: 1px;
    align-items: center;
    justify-content: center;
    color: var(--main-green);
    font-weight: 700;
    background-color: var(--light-green);
    height: 30px;
    width: 30px;
    border-radius: 50%;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    z-index: 99;
    border: 1px solid var(--main-green)
}

.sticky-services-content:hover {
    cursor: pointer;
    background-color: var(--light-green);
}
.sticky-services p {
    color: var(--main-green);
    margin: 5px;
}
.sticky-services-content {
    overflow: hidden;
    display: grid;
    width: 100%;
    padding: 6px 26px;
    grid-template-columns: 85% 15%;
    align-items: center;
    opacity: 0;
    transition: opacity 600ms ease-in-out;
}

.sticky-services-content.confirm {
    display: inline-block;
    background-color: var(--main-green);
    font-weight: 700;
    border-radius: 0 0px 10px 10px;
    transition: all 200ms ease-in-out;
    /* bottom: 0; */
}
.sticky-services-content.confirm:hover {
    background-color: var(--dark-green);
}

.sticky-services-content.confirm p{
    width: 100%;
    color: var(--light-grey);
}
.sticky-qty {
    text-align: right;
    padding-right: 35px;
}
/* Sticky Notifier */
.sticky-notifier {
    display: flex;
    right: 190px;
    bottom: 30px;
    height: 47px;
    background-color: var(--main-green);
    justify-content: center;
    align-items: center;
    z-index: 220;
    width: 0;
    transition: width 500ms ease-in-out;
    border-radius: 5px;
}
.sticky-notifier-open {
    width: 250px;
    padding: 5px 20px;
}
.notifier-text {
    color: var(--light-grey);
    opacity: 1;
    transition: opacity 1000ms ease-in-out;
}
.notifier-text-off {
    opacity: 0;
}
.description {
    overflow-y: auto;
    overflow-x: hidden;
}
/* Cart Section */
.cart-section {
    width: 100%;
    clip-path: polygon(0 7%, 100% 0, 100% 100%, 0% 100%);
    background-color: var(--dark-green);
}



.section {
    height: 100vh;
}

.contact-container {
    position: relative;
    width: 100%;
    display: grid;
    /* grid-template-rows: 40% 60%; */
    text-align: center;
}

.contact-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.contact-cards-container {
    display: grid;    
}

.contact-card:hover,
.contact-card:active
{
    background-color: var(--dark-green) ;
}


.contact-card:hover .contact-card-button,
.contact-card:active .contact-card-button
{
    background-color: var(--main-green) ;
    color: var(--dark-green);
}

.contact-card:hover .contact-card-icon img,
.contact-card:active .contact-card-icon img{
    transform: rotate(5deg);
    filter: invert(74%) sepia(14%) saturate(1805%) hue-rotate(37deg) brightness(96%) contrast(95%);
}

.contact-card-icon img:hover{
    transform: rotate(-5deg) !important;
}


.contact-card-button:hover {
    width: 115px;
    height: 39px;
}

.contact-card-button:hover ~ .contact-card-icon img{
    transform: rotate(-7deg) !important;
}

.contact-info p{
    margin-top: 1em;
}
.contact-popup {
    position: absolute;
    color: transparent;
    background-color: var(--light-grey);
    border-radius: 7px;
    width: 180px;
    overflow: hidden;
    /* height: 0px; */
    
    -webkit-box-shadow: 0 0 17px 2px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 0 17px 2px rgba(0,0,0,0.3);
    box-shadow: 0 0 17px 2px rgba(0,0,0,0.3);
    
    transition: all 400ms ease-in-out;
}
.contact-popup.contact-open-top {
    bottom: 40px;
}
.contact-popup.contact-open-bottom {
    top: 40px;
}

.contact-popup.opened-popup {
    height: 280px;
    color: var(--dark-grey);
}

.contact-popup-contentainer {
    display: none;
}

.contact-popup-contentainer.shown {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact-popup {
    z-index: 99999;
}

.contact-popup-content {
    padding: 0px 10px;
    width: 90%;
    /* background-color: var(--light-grey); */
}

.contact-popup-title {
    color: var(--main-green);
    font-size: 1em;
    font-weight: 700;
    margin: 15px 0px 0 0;
}

.contact-popup-info {
    font-size: 0.8em;
    margin: 3px 0;
}
.contact-popup-info-comp {
    font-size: 0.6em;
}
.mt-15 {
    cursor: pointer;
    margin-top: 1em;
}
.mb-15 {
    margin-bottom: 1em;
}
.contact-popup-button {
    font-size: 0.8em;
    font-weight: bold;
    padding: 0.6em 1.2em;
    border: 1px solid transparent;
    border-radius: 30px;
    color: var(--light-grey);
    background-color: var(--main-green);
    margin-top: 7px;
    cursor: pointer;
    transition: all 300ms ease-in-out;
}


/* Footer */
.footer-popup {
    position: absolute;
    color: transparent;
    background-color: var(--dark-green);
    border-radius: 7px;
    width: 170px;
    height: 0px;
    
    -webkit-box-shadow: 0 0 17px 2px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 0 17px 2px rgba(0,0,0,0.3);
    box-shadow: 0 0 17px 2px rgba(0,0,0,0.3);
    
    transition: all 400ms ease-in-out;
}

.footer-popup.opened-popup {
    height: 280px;
    color: var(--light-grey);
}

.footer-popup-contentainer {
    display: none;
}

.footer-popup-contentainer.shown {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.footer-popup-content {
    padding: 0px 15px;
    width: 90%;
    /* background-color: var(--light-grey); */
}

.footer-popup-title {
    color: var(--main-green);
    font-weight: 700;
    margin: 15px 0px 0 0;
}

.footer-popup-info {
    font-size: 0.7em;
    margin: 3px 0;
}

.footer-popup-button {
    font-size: 0.7em;
    font-weight: bold;
    padding: 0.7em 1.4em;
    border-radius: 30px;
    color: var(--dark-green);
    border: 1px solid var(--main-green);
    background-color: var(--main-green);
    margin-top: 7px;
    cursor: pointer;
    transition: all 300ms ease-in-out;
}

.footer-popup-button:hover {
    background-color: transparent;
    border: 1px solid var(--main-green);
    color: var(--main-green);
}

.footer-info a {
    color: var(--main-green);
    text-decoration: none;
    font-weight: bold;
    transition: all 300ms ease-in-out;
}

.footer-info a:hover {
    color: var(--light-grey);
}

.contact-popup-button:hover,
.contact-popup-button:active,
.contact-popup-button:focus {
    color: var(--main-green);
    background-color: transparent;
    border: 1px solid var(--main-green);
}


.footer-icons-container {
    display: flex;
    justify-content: center;
    position: relative;
    margin-bottom: 30px;
}
.footer-icons-container .footer-icon img{
    filter: invert(74%) sepia(14%) saturate(1805%) hue-rotate(37deg) brightness(96%) contrast(95%);
    cursor: pointer;
    transition: all 300ms ease-in-out;
}

.footer-icons-container .footer-icon img:hover{
    filter: invert(98%) sepia(13%) saturate(0%) hue-rotate(250deg) brightness(117%) contrast(91%);
}

.footer-popup.footer-open-top {
    bottom: 12vh;
}

.footer-popup-imagen {
    width: 90%;
}

.show-card {
    border-radius: 10px;
    border: 2px var(--light-green) solid;
    background-color: var(--light-green)  !important;
    -webkit-box-shadow: 0 0 17px 2px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 0 17px 2px rgba(0,0,0,0.05);
    box-shadow: 0 0 17px 2px rgba(0,0,0,0.05);
}

/* Extra small devices (phones, 600px and down) */

.contact-container::after {
    top: -50%;
    height: 200%;
    width: 100%;
    position: absolute;
    content: "";
    overflow: visible;
    background-image: url("./../assets/img/imaging/BodyBack.svg");
    background-repeat: no-repeat;
    background-size: auto;
    background-position: right;
    z-index: 7;
}
.contact-info,
.contact-cards-container {
    z-index: 99;
}

.service-window-content span:hover {
    background-color: var(--main-green);
    color: var(--light-grey);
}

.input-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

#servForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* Works on Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: var(--main-green) var(--light-green);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 8px;
}

*::-webkit-scrollbar-track {
    background: var(--light-green);
}

*::-webkit-scrollbar-thumb {
    background-color: var(--main-green);
    border-radius: 15px;
}

.rotative-text {
    top: 0em;
}
.txt-rotate {
    transition: all 500ms ease;
    position: relative;
    /* top: -0.7em; */
    /* color: transparent !important; */
    /* margin-top: -20px !important; */
    /* background-color: red; */
    transform: translateY(-20px) !important;
}

.information-container {
    overflow: hidden;
    position: fixed;
    bottom: 0;
    height: 0px;
    width: 100%;
    background: var(--dark-green);
    transition: all 500ms ease-in-out;
    z-index: 2100;
}

.information-content {
    position: absolute;
    width: 100%;
    text-align: center;
}

.information-close {
    padding: 0.1em 0.6em 0.3em 0.6em;
    background-color: transparent;
    border: 1px solid var(--main-green);
    color: var(--main-green);
    position: absolute;
    top: 9px;
    right: 12%;
    font-weight: 700;
    border-radius: 5px;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    z-index: 99;
}

.information-close:hover {
    background-color: var(--main-green);
    color: var(--dark-green);
}

.information-content{
    top: 12px;
}

.information-content b{
    margin: 0 10px;
}

.information-container.open {
    height: 45px;
}
.information-container.copied {
    background-color: var(--main-green);
    height: 45px;
}
.can-desappear {
    display: inline-block;
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 768px) {
    .bars{
        cursor: pointer;
        border-radius: 5px;
        position: fixed;
        padding: 12px 12px 7px 12px;
        top: 10px;
        left: 10px;
        background-color: #fff;
        z-index: 9999999;
        transition: all 300ms linear;
    }
    .bars-open {
        background-color: var(--light-grey);
        left: 65% !important;
    }
    .burger-logo {
        margin-top: 10vh;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width:  130px;
    }
    .open-navbar {
        width: 80% !important; 
    }
    .open-navbar-back {
        width: 100% !important; 
    }
    .nav-container-back {
        top: 0;
        left: 0;
        z-index: 999999;
        width: 0%;
        height: 100vh;
        background-color: #f1f5ecd3;
        transition: all 300ms ease-in-out;
    }
    .nav-container{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100vh !important;
        width: 0%;
        overflow: hidden;
        background-color: var(--light-grey);            
        border-radius: 0px 10px 10px 0px;
        left: 0;
        top: 0;
        transition: all 300ms ease-in-out;
        -webkit-box-shadow: 0 0 17px 2px rgba(54, 78, 31, 0.5) !important;
        -moz-box-shadow: 0 0 17px 2px rgba(54, 78, 31, 0.5) !important;
        box-shadow: 0 0 17px 2px rgba(54, 78, 31, 0.5) !important;
    }
    #certi{
        bottom: 0;
        width: 100%;
        background-color: var(--main-green);
        cursor: pointer;
        padding-top:16px;
        padding-bottom:16px;
        margin-top:35px;
    }
    #certi a {
        font-weight: 700;
        font-size: 20px;
        color:#fff;
        text-decoration: none;
    }
    .navport {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 10vh;
        flex-grow: 1;
    }
    .navport ul {
        flex-grow: 1;
        padding: 0;
    }
    .navport ul a{
        text-decoration: none;
    }
    .navport ul li{
        margin-top: 5px; 
        padding-left: 40px;
        height: 50px;
        display: flex;
        align-items: center;
        text-align: left;
        width:100%;
        border-bottom: 1px solid #f1f5ecd3 !important;
    }
    .navport ul li:hover, .navport ul li:active{
        background-color: var(--main-green);
    }
    .navport ul li:last-child{
        border-bottom: 0px solid var(--light-grey);
    }
    .navport ul li {
        color: var(--dark-green);
        text-decoration: none;
    }
    #Inicio {
        overflow: visible;
        background-image: url("./../assets/img/imaging/HeaderBack.svg");
        background-repeat: no-repeat;
        background-size: auto;
    }
    .footer-popup.footer-open-top {
        bottom: 7vh;
    }
    /* Nav */
    #navBar {
        display: none;
    }
    .logo-sm {
        display: inline-block;
        width: 35%;
        height: auto;
        top: 10vh;
        left: 32.5%;
    }
    .header-content-container {
        margin-top: 30vh;
        margin-left: 10%;
        margin-right: 10%;
        /* height: 70vh; */
    }
    .header-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .header-services {
        margin-left: -10%;
        margin-top: 7vh;
        width: 95vw;
    }
    .h1-md {
        font-size: 35px;
    }
    .p-md {
        font-size: 14px;
    }
    /* Header */
    .main-btn {
        background-color: var(--dark-green);
        bottom: 25%;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 150;
        transition: background-color 200ms ease-in-out, color 0 ease-in-out;
        text-decoration: none;
    }
    .main-btn:hover {
        color: var(--dark-green);
        background-color: var(--main-green);
    }
    /* Services */
    .services-bg {
        background-image: url("../assets/img/ServBackgroundMd.svg");
        top: 0vh;
    }
    .services-container {
        padding-top: 30vh;
    }
    .service-box {
        height: 18vw;
        width: 18vw;
        margin: 1vw;
        text-align: center;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
    }
    .service-btn {
        display: none !important;
    }
    .service-icon {
        opacity: 1;
        width: 35%;
        justify-self: center;
        align-self: center;
    }
    .service-name {
        font-size: 0.6em;
        justify-self: center;
        align-self: center;
    }
    .window-btn{
        margin-left: 0 !important;
    }
    .section-title {
        margin: auto;
        margin-bottom: 8vh;
        top: 15vh;
        left: 10vw;
        color: var(--light-grey);
        font-weight: 700;
    }
    .services-contain {
        justify-content: center;
        justify-items: center;
        align-items: center;
        align-content: center;
        -ms-grid-column-align: center;
        transition: all 300ms ease-in-out;
        border: 2px transparent solid;
        border-radius: 10px;
    }
    .description {
        height: 20vh;
    }
    /* Contact */
    .contact-cards-container {
        margin-top: 5vh;
        grid-template-columns: auto auto;
        grid-template-rows: auto auto;
        justify-content: center;
        align-items: center;
    }
    .contact-card {
        margin: 15px;
        height: 130px;
        width: 130px;
        border-radius: 8px;
        background-color: var(--main-green);
        -webkit-box-shadow: inset 0px 0px 120px -9px rgba(0,0,0,0.236);
        -moz-box-shadow: inset 0px 0px 120px -9px rgba(0,0,0,0.236);
        box-shadow: inset 0px 0px 120px -9px rgba(0,0,0,0.236);
        display: grid;
        grid-template-rows: 60% 40%;
        align-items: center;
        justify-content: center;
    }
    .contact-card-button-container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    .contact-card-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 110px;
        height: 35px;
        border-radius: 30px;
        font-size: 0.8em;
        font-weight: 700;
        color: var(--light-grey);
        background-color: var(--dark-green);
        cursor: pointer;
        transition: background-color 300ms ease-in-out, width 100ms ease-in-out, height 100ms ease-in-out;
    }

    .contact-card-icon img {
        height: 50px;    
        filter: invert(29%) sepia(17%) saturate(1123%) hue-rotate(77deg) brightness(98%) contrast(87%);
        transition: all 400ms ease-in-out;
    }

    .contact-info {
        margin: 30px auto;
        width: 80%;
    }


    .service-window {
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        height: 0;
        width: 100%;
        background-color: #f1f5ecd3;
        transition: height 500ms ease-in-out, width 500ms ease-in-out;
        z-index: 9999999;
    }

    .sticky-notifier {
        display: none;
    }
    .service-window-content-container {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .service-window-content {
        height: 90vh;
        border-radius: 15px;
        width: 90%;
        background-color: var(--light-grey);
        -webkit-box-shadow: 0 0 17px 2px rgba(139, 139, 139, 0.2);
        -moz-box-shadow: 0 0 17px 2px rgba(139, 139, 139, 0.2);
        box-shadow: 0 0 17px 2px rgba(139, 139, 139, 0.2);
        position: relative;
    }
    .sw-image {
        max-height: 70vh;
    }
    .service-window-box {
        height: 100%;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
    .services-window-image {
        position: relative;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        width: 100%;
        height: 45vh;
        background-position: bottom;
        background-size: cover;
    }

    .service-window-image-filter {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        position: absolute;
        height: 40%;
        width: 100%;
        right: 0;
        bottom: 0;
        background: linear-gradient(0deg, var(--light-grey) 0%, #f4f4f428 40%,transparent 100%) 
    }

    .services-window-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-bottom: 30px;
        position: relative;
        /* margin-top: -10vh; */
        height: 55vh;
        width: 90%;
        z-index: 99999;
    }

    .services-window-info h3{
        text-align: center;
    }

    .sticky-cart {
        display: inline-block;
        height: 40px;
        width: 85px;
        bottom: 0;
        right: 0;
        border-radius: 30px;
        margin: 30px;
        z-index: 120000;
        cursor: pointer;
        display: flex;
        align-items: center;
        -webkit-box-shadow: 0 0 17px 2px rgba(110, 161, 62, 0.5) !important;
        -moz-box-shadow: 0 0 17px 2px rgba(110, 161, 62, 0.5) !important;
        box-shadow: 0 0 17px 2px rgba(110, 161, 62, 0.5) !important;
    }

    .cart-icon {
        position: absolute;
        top: 7px;
        left: 12px;
        background-image: url('./../assets/img/icons/cart.svg');
        height: 28px;
        width: 32px;
        background-repeat: no-repeat;
    }
    
    .sticky-title {
        margin: 0;
        padding-top: 0;
        font-size: 15px;
        padding-left: 15px;
        padding-right: 15px;
        color: #fff;
        display: inline-block;
    }

    .sticky-circle {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        top: 5px;
        right: 5px;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background-color: #fff;
    }

    .sticky-services {
        position: relative;
        background-color: #fff;
        width: 220px;
        bottom: 75px;
        right: 30px;
        overflow: auto;
        transition: height 400ms ease-in-out, border-radius 500ms ease-in-out;
        -webkit-box-shadow: 0 0 17px 2px rgba(110, 161, 62, 0.5) !important;
        -moz-box-shadow: 0 0 17px 2px rgba(110, 161, 62, 0.5) !important;
        box-shadow: 0 0 17px 2px rgba(110, 161, 62, 0.5) !important;
    }
    
    .sticky-services-content:hover {
        cursor: pointer;
        background-color: var(--light-green);
    }
    .sticky-services p {
        color: var(--main-green);
        margin: 5px;
    }
    .sticky-services-content {
        overflow: hidden;
        display: grid;
        width: 100%;
        padding: 6px 16px;
        grid-template-columns: 85% 15%;
        align-items: center;
        opacity: 0;
        transition: opacity 1000ms ease-in-out;
    }

    /* Footer */
    .footer-container {
        position: relative;
        height: 25vh;
        background-color: var(--dark-green);
        border-top-left-radius: 25vw;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2150;
    }
    .footer-container::before {
        position: absolute;
        content: "";
        height: 100%;
        width: 78%;
        clip-path: polygon(0 15%, 100% 0, 100% 100%, 0% 100%);
        right: 0;
        top: -3.5vh;
        background-color: var(--dark-green);
    }

    .footer-content {
        color: var(--main-green);
        text-align: center;
        width: 65%;
        z-index: 12000;
    }
    .footer-icons-container {
        margin-bottom: 10px;
    }
    .footer-icon img{
        height: 25px;
        margin: 5px 15px;
    }

    #Noticias {
        margin-top: 10vh;
        position: static;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    #Contacto {
        margin-top: 10vh;
    }

    footer {
        margin-top: 10vh;
    }

    .noticias-top img, .noticias-bottom img{
        display: none;
    }

    #carousel {
        background-color: transparent !important;
    }

    .noticias-top, .noticias-top .section-title {
        position: static !important;
    }

    .vigilado-logo {
        margin-top: 10px;
        width: 150px;
    }

    .information-content {
        text-align: left;
        top: 16px;
        left: 20px;
        font-size: 0.8em;
    }
    .information-content b{
        margin: 0 5px;
    }

    .information-close {
        top: 12px;
        font-size: 0.8em;
    }

    .can-appear {
        display: inline-block;
    }
    .can-desappear {
        display: none;
    }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .service-counter-container {
        margin-top: 8vh;
    }
    .sm-sl {
        margin-top: -0.5em; 
    }
    .can-appear {
        display: none;
    }
    /* Nav */
    #navBar {
        display: inline-block;
    }

    #navbox {
        display: none;
    }

    .logo-sm {
        display: none;
    }
    .nav-bar {
        justify-content: space-between;
    }
    .col-md-3 {
        width: 25%;
    }
    .col-md-9 {
        width: 75%;
    }
    /* Header */
    .header-content-container {
        min-height: calc(100vh);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 90%;
        margin-top: 0;
        margin-left: auto;
        margin-right: auto;
        z-index: 95;
    }
    .header-content {
        text-align: center;
        height: 30%;
        width: 60%;
        margin-top: 15vh;
        transition: all 300ms ease-in-out;
    }

    .header-services {
        /* height: 45%; */
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .main-btn {
        margin-top: 20px;
        padding: 15px 30px !important;
        font-size: 20px !important;
        z-index: 200;
    }
    .main-btn-md {
        display: none;
    }
    .h1-md {
        font-size: 45px;
    }
    h4 {
        font-size: 1.2em;
        font-weight: 600;
    }
    .h5-md {
        font-size: 18px;
        margin: 8px 0;
    }

    h2 {
        font-size: 28px;
    }
    p {
        font-size: 18px;
    }
    .p-md {
        font-size: 18px;
    }
    /* Services */
    .services-bg {
        background-image: url("../assets/img/ServBackgroundSm.svg");
    }
    .section-title h1 {
        display: inline;
    }
    .service-box {
        text-align: center;
        width: 110px;
        height: 110px;
        margin: 10px;
    }
    .service-box:hover .service-btn {
        display: inline-block;
        opacity: 1;
    }
    .service-box:hover .service-icon {
        display: none;
        opacity: 0;
    }
    .service-box:active,
    .service-box:hover {
        background-color: var(--main-green);
    }
    .service-box:hover .service-btn{
        background-color: var(--light-grey);
        color: var(--main-green);
    }
    .service-icon {
        opacity: 1;
        width: 40px;
    }

    .section-title {
        text-align: center;
    }

    .service-name {
        font-size: 18px;
    }

    .service-box:hover h4 {
        color: var(--light-grey) !important
    }
    .description {
        padding-left: 10px;
        padding-right: 10px;
        max-height: 22vh;
    }

    #Contacto {
        margin-top: 20vh;
    }

    footer {
        margin-top: 15vh;
    }
    .contact-info p{
        width: 60%;
    }

    /* Sticky Counter */
    .sticky-cart {
        z-index: 3200;
    }
    .publicity-container{
        height: 55vh;
    }
    /* Footer */
    .footer-container {
        position: relative;
        height: 35vh;
        background-color: var(--dark-green);
        border-top-left-radius: 25vw;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2150;
    }
    .footer-container::before {
        position: absolute;
        content: "";
        height: 100%;
        width: 78%;
        clip-path: polygon(0 15%, 100% 0, 100% 100%, 0% 100%);
        right: 0;
        top: -5vh;
        background-color: var(--dark-green);
    }

    .footer-content {
        color: var(--main-green);
        text-align: center;
        width: 50%;
        z-index: 12000;
    }

    .footer-icon img{
        height: 40px;
        margin: 10px 20px;
    }

    #Inicio {
        overflow: visible;
        background-image: url("./../assets/img/imaging/HeaderBack.svg");
        background-repeat: no-repeat;
        background-size: auto;
    }

     /* Contact */
    .contact-cards-container {
        margin-top: 10vh;
        grid-template-columns: auto auto;
        grid-template-rows: auto auto;
        justify-content: center;
        align-items: center;
    }

    
    .contact-card {
        margin: 15px;
        height: 180px;
        width: 200px;
        border-radius: 8px;
        background-color: var(--main-green);
        -webkit-box-shadow: inset 0px 0px 120px -9px rgba(0,0,0,0.236);
        -moz-box-shadow: inset 0px 0px 120px -9px rgba(0,0,0,0.236);
        box-shadow: inset 0px 0px 120px -9px rgba(0,0,0,0.236);
        transition: all 300ms ease-in-out;
        display: grid;
        grid-template-rows: 60% 40%;
        align-items: center;
        justify-content: center;
    }

    
    .contact-card-title {
        width: 200px;
        text-align: center;
        font-size: 1.4em;
        font-weight: 700;
        color: var(--light-grey);
    }


    .contact-card-button-container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }


    .contact-card-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 110px;
        height: 35px;
        border-radius: 30px;
        font-size: 0.8em;
        font-weight: 700;
        color: var(--light-grey);
        background-color: var(--dark-green);
        cursor: pointer;
        transition: background-color 300ms ease-in-out, width 100ms ease-in-out, height 100ms ease-in-out;
    }

    .contact-card-icon img {
        height: 70px;
        filter: invert(27%) sepia(32%) saturate(692%) hue-rotate(77deg) brightness(98%) contrast(81%);
        transition: all 400ms ease-in-out;
    }

    #Noticias {
        /* margin-top: 10vh; */
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .noticias-top img{
        margin-left: -5vw;
        height: 110%;
    }

    .noticias-bottom img{
        margin-top: -20vh;
    }

    .section-title {
        margin-left: 70px;
        margin-bottom: 8vh;
        top: 15vh;
        left: 10vw;
        color: var(--light-grey);
        font-weight: 700;
    }

    .service-window {
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        height: 0;
        width: 100%;
        background-color: #f1f5ecd3;
        transition: height 500ms ease-in-out, width 500ms ease-in-out;
        z-index: 9999999;
    }
    .service-window-content-container {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .service-window-content {
        height: 80vh;
        border-radius: 15px;
        width: 80%;
        background-color: var(--light-grey);
        -webkit-box-shadow: 0 0 17px 2px rgba(139, 139, 139, 0.2);
        -moz-box-shadow: 0 0 17px 2px rgba(139, 139, 139, 0.2);
        box-shadow: 0 0 17px 2px rgba(139, 139, 139, 0.2);
        position: relative;
    }

    .service-window-content span {
        top: 20px;
        right: 20px;
        display: flex;
        padding-bottom: 2px;
        align-items: center;
        justify-content: center;
        color: var(--main-green);
        font-weight: 700;
        background-color: var(--light-green);
        height: 30px;
        width: 30px;
        border-radius: 50%;
        cursor: pointer;
        transition: all 200ms ease-in-out;
        z-index: 99;
        border: 1px solid var(--main-green)
    }

    .sw-image {
        max-height: 70vh;
    }
    .service-window-box {
        padding: 3%;
        height: 100%;
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .services-window-image {
        border-radius: 8px;
        width: 40%;
        height: 100%;
        background-position: center;
        background-size: cover;
    
        -webkit-box-shadow: 0 0 17px 2px rgba(139, 139, 139, 0.2);
        -moz-box-shadow: 0 0 17px 2px rgba(139, 139, 139, 0.2);
        box-shadow: 0 0 17px 2px rgba(139, 139, 139, 0.2);
    
    }
    .services-window-info {
        width: 60%;
        height: 100%;
        padding-left: 4%;
        padding-right: 1%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .services-window-info h3{
        text-align: center;
    }

    .vigilado-logo {
        margin-top: 20px;
        width: 200px;
    }

    .cart-icon {
        position: absolute;
        top: 8px;
        left: 10px;
        background-image: url('./../assets/img/icons/cart.svg');
        height: 33px;
        width: 38px;
        background-repeat: no-repeat;
    }

}
/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 1080px) {
    /* Nav */
    .nav-bar {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .active-nav {
        height: 70px;
        position: relative;
        background-color: #f4f4f4;
        z-index: 99;
        transition: background-color 400ms ease-in-out;
        -webkit-box-shadow: 0 0 17px 2px rgba(0, 0, 0, 0.05);
        -moz-box-shadow: 0 0 17px 2px rgba(0,0,0,0.05);
        box-shadow: 0 0 17px 2px rgba(0,0,0,0.05);
    }

    .logo-lg,
    .nav-bar {
        display: block;
        z-index: 210;
    }
    .logo-md-sm {
        display: none;
    }
    .col-md-5 {
        width: 41.6666%;
    }
    .col-md-7 {
        width: 58.3333%;
    }
    .col-md-9 {
        width: calc(100% * 9 / 12);
    }
    /* Header */
    .header-content-container {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        text-align: left;
        z-index: 95;
    }
    .header-services {
        width: 60%;
        height: auto;
    }
    .header-content {
        text-align: left;
        width: 35%;
        height: auto;
        margin-top: 0;
    }
    /* Services */
    .services-bg {
        background-image: url("../assets/img/ServBackground.svg");
        top: -45vh;
    }
    .services-container {
        padding-top: 40vh;
    }
    .service-box {
        height: 130px;
        width: 20%;
        /* min-width: 180px; */
        margin: 10px;
    }
    .service-icon {
        opacity: 1;
        width: 35%;
    }
    .service-name {
        font-size: 18px;
    }

    h2 {
        font-size: 35px;
    }

    /* Contact */
    .contact-cards-container {
        margin-top: 10vh;
        grid-template-columns: auto auto auto auto;
        justify-content: center;
        align-items: center;
    }
    
}

@media only screen and (min-width: 850px) {
    .noticias-top img{
        margin-left: -5vw;
        height: 110%;
    }

    .noticias-bottom img{
        margin-top: -20vh;
    }

    #carousel {
        /* margin-top: -15vh; */
    }

}

@media only screen and (min-width: 1225px) {
    .noticias-top img{
        margin-left: -5vw;
        height: 110%;
    }

    .noticias-bottom img{
        margin-top: -30vh;
    }

    #carousel {
        /* margin-top: -30vh; */
    }

}
@media only screen and (min-width: 1200px) {
    /* Nav */
    .nav-bar {
        z-index: 210;
    }
    .col-lg-6 {
        width: 50%;
    }
    /* Header */
    .header-filter {
        background-image: linear-gradient(rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.75) 80%);
    }
    .header-bg {
        height: 100vh;
        background-image: url("../assets/img/headerbg.png");
        background-size: cover;
        -webkit-transform: scaleX(-1.5) scaleY(1.5) translate(0,-4vh);
        transform: scaleX(-1.5) scaleY(1.5) translate(0,-4vh);
        z-index: 11;
    }
    .header-content-container {
        z-index: 95;
    }
    .header-services {
        width: 55%;
    }
    /* Services */
    .services-bg {
        /* background-image: url("../assets/img/ServBackground.svg"); */
        top: -45vh;
    }
    .services-container {
        padding-top: 65vh;
    }
}
.logo {
    cursor: pointer;
}
/* Flex */
.row {
    display: flex;
}
.flex-center {
    justify-content: end;
    align-items: center;
}
.flex-center-self {
    justify-self: end;
    align-self: center;
}
/* Grid */
.grid {
    display: grid;
}
.grid-service {
    align-items: center;
    justify-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
/* Position */
.fixed {
    position: fixed;
}
.sticky {
    position: sticky;
}
.absolute {
    position: absolute;
}
.relative {
    position: relative;
}
/* Lettering */
h1 {
    font-size: 45px;
    font-weight: 400;
}
/* h2 {
    font-weight: 700;
} */
h4 {
    font-size: 18px;
}
h5 {
    font-size: 12px;
}
.text-center {
    text-align: center;
}
.mont {
    font-family: 'Montserrat', sans-serif;
}

/* Font Colors */
.green {
    color: var(--main-green);
}
.dark-green {
    color: var(--dark-green);
}
.dark-grey {
    color: var(--dark-grey);
}
.light-grey {
    color: var(--light-grey);
}
/* Helpers */
.w-100 {
    width: 100%;
}
.invisible {
    display: none;
}
.visible {
    display: inline-block;
}
/* Backgrounds */
.dark-green-bg {
    background-color: var(--dark-green);
}
.main-green-bg {
    background-color: var(--main-green);
}
.main-danger-bg {
    background-color: #c02121;
    border: 1px solid #c02121 !important;
}
/* Buttons */
.btn {
    border: 0;
    border-radius: 8px;
    cursor: pointer;
}
.main-btn {
    padding: 12px 20px;
    margin-top: 20px;
    font-weight: bold;
    font-size: 18px;
    transition: background-color 200ms ease-in-out, color 200ms ease-in-out,;
}

#servForm input[type="number"] {
    width: 100px;
}
.window-btn {
    margin-left: 40px;
    color: #fff;
    font-weight: bold;
    transition: background-color 200ms ease-in-out, color 200ms ease-in-out,;
}
.window-btn:hover, .counter-btn:hover {
    color: var(--main-green);
    background-color: var(--dark-green);
    border: solid 1px var(--dark-green);
}

.counter-btn {
    margin: 0 10px;
    display: flex;
    padding-bottom: 4px;
    padding-right: 1px;
    align-items: center;
    justify-content: center;
    color: var(--light-grey);
    font-weight: 700;
    background-color: var(--main-green);
    height: 25px;
    width: 25px;
    border-radius: 50%;
    cursor: pointer;
    transition: all 200ms ease-in-out;
}
/* Inputs */
.input {
    border: solid 1px var(--main-green);
    border-radius: 30px;
    padding: 6px 12px;
    margin: 5px;
}

.input:active, .input:focus {
    border: solid 1px var(--main-green);
    outline: transparent;
}

.no-spinner::-webkit-outer-spin-button,
.no-spinner::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.no-spinner[type=number] {
  -moz-appearance: textfield;
}

/* Visibility - Display - Opacity */
.invisible {
    display: none;
    visibility: hidden;
    opacity: 0;
}
.visible {
    display: inline-block;
    visibility: visible;
    opacity: 1;
}
/* Shadow */
.shadow {
    -webkit-box-shadow: 0 0 17px 2px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 0 17px 2px rgba(0,0,0,0.05);
    box-shadow: 0 0 17px 2px rgba(0,0,0,0.05);
}
/* Debug */
.debug {
    border: solid 1px red;
}
