:root {
    --carousel-height: 80vh;
}

.carousel-title-container {
    /* top: 100px; */
    z-index: 30;
    padding-left: 18%;
    font-size: 50px;
}

.carousel-title2-container {
    text-align: right;
    bottom: 100px;
    z-index: 30;
    right: 18%;
    font-size: 80px;
}

.btn-servicios {
    font-size: 27px;
    border-radius: 10px;
    padding: 15px 40px;
    cursor: pointer;
}

.btn-servicios-container {
    top: 14%;
    right: 18%;
}



#carousel {
    padding-left: 70px;
    /* background-color: var(--main-green); */
    z-index: 10;
    padding-right: 70px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.rounded-chart-left {
    border-radius: 10px 0 0 10px;
}
.rounded-chart-right {
    border-radius: 0 10px 10px 0;
}

/* Slideshow container */
.slideshow-container {
    overflow: hidden;
    height: var(--carousel-height);
    width: 40%;
    margin: auto;
    z-index: 10;
}
.content-container {
    height: var(--carousel-height);
    width: 60%;
    margin: auto;
    background-color: #fff;
    padding: 30px 60px;
    z-index: 9;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* Title */
.title-container h2 {
    color: var(--main-green);
}

.carousel-info {
    margin-top: 1.5em;
    text-align: justify;
    color: var(--dark-grey);
}

/* Hide the images by default */
.mySlides {
    display: none;
}

.carousel-img {
    height: var(--carousel-height);
    width: 100%;
    object-fit: cover;
}

.carousel-info-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* Next & previous buttons */
.prev-control, .next-control {
    position: absolute;
    height: var(--carousel-height);
    width: 60px;
    cursor: pointer;
}
.prev-control {
    left: 0;
    top: 0;
    background: linear-gradient(90deg, rgba(27, 27, 27, 0.184) 30%, transparent);
}

.next-control {
    background: linear-gradient(-90deg, rgba(27, 27, 27, 0.184) 30%, transparent);
    right: 0;
    top: 0;
}
.next,
.prev {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 16px;
    color: var(--main-green);
    font-weight: bold;
    font-size: 25px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
}

/* Position the "next button" to the right */
.next {
    right: 0;
    border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.next:active,
.next:hover,
.prev:active,
.prev:hover {
    background-color: var(--primary-green);
    color: #fff;
}

/* Caption text */
.text {
    font-size: 20px;
    font-weight: 400;
    color: #f2f2f2;
    margin-left: 60px;
    margin-right: 60px;
    bottom: 200px;
    padding: 20px 28px;
    background-color: rgba(153, 204, 051, 0.6);
    bottom: 20px;
    border-radius: 10px;
}

/* The dots/bullets/indicators */
.dot-container {
    margin-top: 30px;
    margin-left: 0;
}
.dot {
    cursor: pointer;
    background-color: #fff;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
}
.caroussel-active,
.dot:hover{
    background-color: var(--dark-green) !important;
}

.dot:hover i{
    color: var(--main-green) !important;
}

.caroussel-active i {
    color: var(--main-green) !important;
}

.dot i{
    color: var(--dark-green);
}
.color-white {
    color: #fff;
}

/* Fading animation */
.fade {
    -webkit-animation-name: fade;
    -webkit-animation-duration: 2.5s;
    animation-name: fade;
    animation-duration: 2.5s;
}
@-webkit-keyframes fade {
    from {
        opacity: 0.1;
    }
    to {
        opacity: 1;
    }
}
@keyframes fade {
    from {
        opacity: 0.1;
    }
    to {
        opacity: 1;
    }
}

@media only screen and (max-width: 768px) {

    #carousel {
        width: 80%;
        padding: 0 !important;
        margin: auto;
        background-color: var(--main-green);
        z-index: 10;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        -webkit-box-shadow: 0 0 17px 2px rgba(0, 0, 0, 0.05);
        -moz-box-shadow: 0 0 17px 2px rgba(0,0,0,0.05);
        box-shadow: 0 0 17px 2px rgba(0,0,0,0.05);
        border-radius: 10px;
    }

    .slideshow-container {
        overflow: hidden;
        min-height: 35vh;
        max-height: 250px;
        width: 100%;
        -webkit-box-shadow: 0 0 17px 2px transparent !important;
        -moz-box-shadow: 0 0 17px 2px transparent !important;
        box-shadow: 0 0 17px 2px transparent !important;
    }

    .slideshow-container::after {
        content: "";
        position: absolute;
        height: 30%;
        width: 100%;
        /* top: 0; */
        bottom: 0px;
        right: 0;
        left: 0;
        background: linear-gradient(0deg, #fff 15%, transparent) 
    }
    .content-container {
        min-height: 45vh;
        max-height: 350px;
        width: 100%;
        padding: 20px 30px;
        -webkit-box-shadow: 0 0 17px 2px transparent !important;
        -moz-box-shadow: 0 0 17px 2px transparent !important;
        box-shadow: 0 0 17px 2px transparent !important;
        z-index: 9999;
    }

    .next, .prev {
        top: 25% !important;
    }

    .rounded-chart-right {
        border-radius: 0 0px 10px 10px;
    }

    .rounded-chart-left {
        border-radius: 10px 10px 0 0;
    }
    .dot-container {
        margin-left: -15%;
        width: 130%;
    }
    .dot {
        min-height: 10px;
        min-width: 10px;
        height: 40px;
        width: 40px;
        margin: 0 6px;
    }

    .title-container h2 {
        font-size: 19px;
    }

    .slideshow-container span {
        z-index: 999999;
    }
}

@media only screen and (min-width: 768px) {
    .dot {
        height: 20px;
        width: 20px;
        margin: 0 8px;
    }
}

@media only screen and (min-width: 1080px) {
    .dot {
        height: 30px;
        width: 30px;
        margin: 0 15px;
    }
}

@media only screen and (min-width: 1080px) {
    .slideshow-container {
        display: inline-block;
        width: 40%;
    }

    .content-container {
        width: 60%;
    }
}