	/*
  	Flaticon icon font: Flaticon
  	Creation date: 29/05/2020 05:06
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}


@media (max-width: 768px) {
  [class^="flaticon-"]:before, [class*=" flaticon-"]:before,
  [class^="flaticon-"]:after, [class*=" flaticon-"]:after { 
  top:6px !important;
  right:7px !important;
  font-size: 25px !important;
font-family: Flaticon;

font-style: normal;
margin: 0px;
position:absolute;
color: var(--primary-green);
  }
}

@media (min-width: 768px) {
  [class^="flaticon-"]:before, [class*=" flaticon-"]:before,
  [class^="flaticon-"]:after, [class*=" flaticon-"]:after { 
  top:8px;
  right:9px;
  font-size: 22px;
font-family: Flaticon;

font-style: normal;
margin: 0px;
position:absolute;
color: var(--primary-green);
  }
}

@media (min-width: 1080px) {
[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
font-size: 30px;
font-style: normal;
margin: 0px;
position:absolute;
top:15px;
right:15px;
color: var(--primary-green);
}
}



.flaticon-medical:before { content: "\f100"; }
.flaticon-documents:before { content: "\f101"; }
.flaticon-footprints:before { content: "\f102"; }
.flaticon-washing-hands:before { content: "\f103"; }
.flaticon-handwash:before { content: "\f104"; }
.flaticon-affection:before { content: "\f105"; }
.flaticon-prevention:before { content: "\f106"; }